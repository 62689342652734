<template>
  <fragment>
    <div
      class="app-container apply__new"
      style="background-color: #f6f6f6; padding: 100px 0px 120px;"
    >
      <h1 class="apply__tit font-bold text-3xl mt-5 mb-5">
        <span class="highlight">
          <span>📑 작성한 지원서 다시보기</span>
        </span>
      </h1>
      <div
        class="apply__container"
        style="border: 1px solid #f1f1f1;"
      >
        <div
          class="applyNew__preview"
          width="960px;"
        >
          <el-row class="mt-5 mb-5 p-5 text-black">
            <el-col>
              <h4 class="text-2xl font-bold mb-2">
                지원 정보
              </h4>
              <div class="mt-6 ml-1.5 info__list">
                <p class="text-xl inline-block">
                  {{ jobName }}
                </p>
              </div>
              <el-divider
                class="mt-8 mb-10 bg-gray-400"
                style="height: 0.1rem;"
              />
            </el-col>
            <el-col>
              <h4 class="text-2xl font-bold mb-2">
                기본 인적사항
              </h4>
              <div class="mt-6 ml-1.5 mb-2 info__list">
                <p
                  v-if="applicantForm.name"
                  class="text-lg inline-block"
                  style="margin-right: 15px;"
                >
                  {{ applicantForm.name }}
                </p>
                <p
                  v-if="applicantForm.englishName"
                  class="text-lg inline-block"
                  style="margin-right: 15px;"
                >
                  {{ applicantForm.englishName }}
                </p>
                <p
                  v-if="applicantForm.birthday"
                  class="text-lg inline-block"
                  style="margin-right: 15px;"
                >
                  {{ applicantForm.birthday }}
                </p>
                <p
                  v-if="applicantForm.sex"
                  class="text-lg inline-block"
                  style="margin-right: 15px;"
                >
                  {{ applicantForm.sex }}
                </p>
                <p
                  v-if="applicantForm.sex==='남자'"
                  class="text-lg inline-block"
                >
                  {{ armyStatusList[applicantForm.armyStatus] }}
                </p>
              </div>
              <div class="ml-1.5 info__list">
                <p
                  class="text-lg inline-block"
                  style="margin-right: 15px;"
                >
                  {{ applicantForm.email }}
                </p>
                <p class="text-lg inline-block">
                  {{ applicantForm.phone }}
                </p>
              </div>
              <el-divider
                class="mt-10 mb-10 bg-gray-400"
                style="height: 0.1rem;"
              />
            </el-col>
            <el-col>
              <h4 class="text-2xl font-bold mb-2">
                추가 인적사항
              </h4>
              <div class="mt-6 ml-1.5 mb-2 text-sm info__list">
                <p
                  class="text-lg inline-block"
                  style="margin-right: 15px;"
                >
                  <b>보훈대상 여부 : </b> {{ patriot ? "대상" : "비대상" }}
                </p>
                <p class="text-lg inline-block">
                  <b>장애사항 : </b> {{ handicapList[handicap] }}
                </p>
              </div>
              <el-divider
                class="mt-10 mb-10 bg-gray-400"
                style="height: 0.1rem;"
              />
            </el-col>
            <el-col>
              <h4 class="text-2xl font-bold mb-2">
                학력사항
              </h4>
              <el-table :data="educations">
                <el-table-column label="구분">
                  <template slot-scope="scope">
                    {{ eduTypeList[scope.row.type] }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="학교"
                  prop="name"
                />
                <el-table-column label="학과">
                  <template slot-scope="scope">
                    {{ scope.row.department || '-' }} /
                    {{ scope.row.subDepartment || '-' }}
                  </template>
                </el-table-column>
                <el-table-column label="기간">
                  <template slot-scope="scope">
                    {{ scope.row.startDate }} ~ {{ scope.row.endDate }}
                  </template>
                </el-table-column>
              </el-table>
              <el-divider
                class="mt-10 mb-10 bg-gray-400"
                style="height: 0.1rem;"
              />
            </el-col>
            <el-col>
              <h4 class="text-2xl font-bold mb-2">
                자격/어학사항
              </h4>
              <el-table :data="certifications">
                <el-table-column label="구분">
                  <template slot-scope="scope">
                    {{ certiTypeList[scope.row.type] }}
                    <span v-if="scope.row.type">/ {{ scope.row.lang || '-' }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="시험/자격 종류">
                  <template slot-scope="scope">
                    {{ scope.row.exam || '-' }}
                  </template>
                </el-table-column>
                <el-table-column label="등급">
                  <template slot-scope="scope">
                    {{ scope.row.grade || '-' }}
                  </template>
                </el-table-column>
                <el-table-column label="점수">
                  <template slot-scope="scope">
                    {{ scope.row.score || '-' }}
                  </template>
                </el-table-column>
                <el-table-column label="취득/응시일">
                  <template slot-scope="scope">
                    {{ scope.row.date || '-' }}
                  </template>
                </el-table-column>
                <el-table-column label="발급기관">
                  <template slot-scope="scope">
                    {{ scope.row.organization || '-' }}
                  </template>
                </el-table-column>
              </el-table>
              <el-divider
                class="mt-10 mb-10 bg-gray-400"
                style="height: 0.1rem;"
              />
            </el-col>
            <el-col>
              <h4 class="text-2xl font-bold mb-2">
                경력사항
              </h4>
              <el-table :data="careers">
                <el-table-column
                  label="회사"
                  prop="company"
                  width="110"
                />
                <el-table-column
                  label="부서"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.department || '-' }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="기간"
                  width="140"
                >
                  <template slot-scope="scope">
                    {{ scope.row.startDate }} ~ {{ scope.row.endDate }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="직위"
                  prop="position"
                  width="80"
                />
                <el-table-column
                  label="직무"
                  prop="job"
                  width="80"
                />
                <el-table-column
                  label="연봉"
                  width="80"
                >
                  <template slot-scope="scope">
                    {{ scope.row.salary ? `${scope.row.salary}만원`: '-' }}
                  </template>
                </el-table-column>
                <el-table-column label="퇴사사유">
                  <template slot-scope="scope">
                    {{ scope.row.isNowChecked ? "재직중" : scope.row.reason }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="상세내용"
                  type="expand"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.desc }}
                  </template>
                </el-table-column>
              </el-table>
              <div class="pt-4">
                <p
                  v-for="upload in careerUpload"
                  :key="upload.uid"
                  class="mt-2 mb-2 mr-2 text-right"
                >
                  {{ upload.name }}
                </p>
              </div>
              <el-divider
                class="mt-10 mb-10 bg-gray-400"
                style="height: 0.1rem;"
              />
            </el-col>
            <el-col>
              <h4 class="text-2xl font-bold mb-2">
                프로젝트 수행이력
              </h4>
              <el-table :data="projects">
                <el-table-column
                  label="프로젝트명"
                  prop="title"
                />
                <el-table-column
                  label="조직"
                  prop="group"
                />
                <el-table-column label="기간">
                  <template slot-scope="scope">
                    {{ scope.row.startDate }} ~ {{ scope.row.endDate }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="상세 내용"
                  type="expand"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.desc }}
                  </template>
                </el-table-column>
              </el-table>
              <el-divider
                class="mt-10 mb-10 bg-gray-400"
                style="height: 0.1rem;"
              />
            </el-col>
            <el-col>
              <h4 class="text-lg font-bold mb-2">
                보유 기술
              </h4>
              <div class="p-2 mb-3">
                {{ skill }}
              </div>
              <!-- <h4 class="text-lg font-bold mb-2">
                지원 동기
              </h4>
              <div class="p-2 mb-3">
                {{ introduction }}
              </div>
              <h4 class="text-lg font-bold mb-2">
                강점 및 목표
              </h4>
              <div class="p-2 mb-3">
                {{ goal }}
              </div> -->
            </el-col>
            <el-col>
              <el-row>
                <h4 class="text-lg font-bold mb-5">
                  첨부자료
                </h4>
                <p
                  v-for="upload in uploadList"
                  :key="upload.uid"
                  class="mb-2 ml-2"
                >
                  {{ upload.name }}
                </p>
                <p
                  v-for="(url, urlIdx) in urlList"
                  :key="`showUrl${urlIdx}`"
                  class="ml-2"
                >
                  <a
                    class="cursor-pointer text-blue-500 mb-2"
                    :href="url"
                    target="_blank"
                  >{{ url }}</a>
                </p>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </fragment>
</template>
<script>
import { getAllJobs } from '@/api/job';
import { getApplyDetail } from '@/api/applicant';
import '@/plugins/element.js';

export default {
  name: 'ReviewApply',
  data() {
    return {
      applicationId: 0,
      formData: this.applicantForm,
      armyStatusList: [
        this.$t('recruit.form.army_done'),
        this.$t('recruit.form.army_skip'),
        this.$t('recruit.form.army_notYet'),
        this.$t('recruit.form.army_proIng'),
        this.$t('recruit.form.army_none'),
      ],
      eduTypeList: [
        this.$t('recruit.form.edu_type.0'),
        this.$t('recruit.form.edu_type.1'),
        this.$t('recruit.form.edu_type.2'),
        this.$t('recruit.form.edu_type.3'),
      ],
      certiTypeList: [
        this.$t('recruit.form.certi_type.0'),
        this.$t('recruit.form.certi_type.1'),
      ],
      projectTypeList: [
        this.$t('recruit.form.project_type.0'),
        this.$t('recruit.form.project_type.1'),
        this.$t('recruit.form.project_type.2'),
        this.$t('recruit.form.project_type.3'),
      ],
      appliableList: [],
      jobName: '',
      applicantForm: {
        recruitPoolCheck: false,
        name: '',
        englishName: '',
        birthday: '',
        email: '',
        phone: '',
        sex: '',
        armyStatus: 3,
        jobId: '',
        incomingRouteId: '',
        applyRouteId: 9,
      },
      educations: [],
      certifications: [],
      skill: '',
      careers: [],
      projects: [],
      // introduction: '',
      // goal: '',
      uploadList: [],
      careerUpload: [],
      urlList: [''],
      patriotYN: '',
      patriot: '',
      handicap: '',
      handicapList: [
        this.$t('recruit.form.handicap_none'),
        this.$t('recruit.form.handicap_normal'),
        this.$t('recruit.form.handicap_work'),
        this.$t('recruit.form.handicap_patriot'),
      ],
    };
  },
  computed: {
  },
  watch: {
  },
  async created() {
    this.applicationId = this.$store.state.user.applicationId || window.sessionStorage.getItem('applicationId');

    await this.getJobList();

    const applicationId = this.applicationId;
    const tempData = await getApplyDetail({ applicationId });
    const formData = tempData.data.item;

    this.applicantForm = formData.applicantForm;
    this.patriot = formData.patriot;
    this.patriotYN = this.patriot === true ? 'patriotY' : 'patriotN';
    this.handicap = formData.handicap;
    // this.goal = formData.goal;
    // this.introduction = formData.introduction;
    this.skill = formData.skill;
    this.educations = formData.educations;
    this.certifications = formData.certifications;
    this.careers = formData.careers;
    this.projects = formData.projects;
    this.uploadList = formData.uploadList;
    this.careerUpload = formData.careerUpload;

    if(formData.urlList&&formData.urlList.length > 0){
      this.urlList = formData.urlList;
    }

    this.jobName = this.appliableList.filter(item => item.id === this.applicantForm.jobId);
    this.jobName = this.jobName[0].name;
  },
  methods: {
    async getJobList() {
      const query = {};

      const result = await getAllJobs(query);
      // const list = [];
      // result.data.items.forEach(type => {
      //   type.job.forEach(j => {
      //     list.push({
      //       type: type.name,
      //       type_en: type.name_en,
      //       ...j
      //     });
      //   });
      // });
      this.appliableList = result.data.items;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/apply.scss';
</style>
